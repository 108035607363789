import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../features/auth/authSlice';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faList, faRightFromBracket, faClipboardList, faIdCard } from '@fortawesome/free-solid-svg-icons';



const Header = () => {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <>
            <header className="header">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg header-nav">
                        <div className="navbar-header">
                            <a id="mobile_btn" href="javascript:void(0);">
                                <span className="bar-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </a>
                            <Link to="/" className="navbar-brand logo">
                                <img src="/assets/img/logo7.png" className="img-fluid" alt="Logo" />
                            </Link>
                            <Link to="/" className="navbar-brand logo-small">
                                <img src="/assets/img/logo2.png" className="img-fluid" alt="Logo" />
                            </Link>
                        </div>
                        <div className="main-menu-wrapper">
                            <div className="menu-header">
                                <Link to="/" className="menu-logo">
                                    <img src="/assets/img/logo7.png" className="img-fluid" alt="Logo" />
                                </Link>
                                <a id="menu_close" className="menu-close" href="javascript:void(0);">
                                    <i className="fas fa-times"></i>
                                </a>
                            </div>
                            <ul className="main-nav">
                                <li className="active"><Link to="/"> Home</Link></li>
                                {/* <FontAwesomeIcon icon={faList} /> */}
                                <li><Link to="/booking-summary">Booking Summary</Link></li>
                                <li><Link to="/booking-status">Booking Status</Link></li>
                                <li><Link to="/booking-history">Booking History</Link></li>

                                <li><Link to="/booking-summary"> My Rides</Link></li>
                                {/* <FontAwesomeIcon icon={faIdCard} />  */}
                                <li><Link to="/contact-us"> Contact Us</Link></li>
                                {/* <FontAwesomeIcon icon={faClipboardList} /> */}
                                <li><Link to="/policy"> Policy</Link></li>
                                {!token ? (
                                    <>
                                        <li className="login-link">
                                            <Link to="/register">Sign Up</Link>
                                        </li>
                                        <li className="login-link">
                                            <Link to="/login">Sign In</Link>
                                        </li>
                                    </>
                                ) : (
                                    // <li className="login-link">
                                    //     <button className="btn-logout" onClick={handleLogout} ><i class="fa-regular fa-right-from-bracket"></i>Logout</button>
                                    // </li>
                                    <li className="login-link">
                                        <button
                                            className="btn-logout"
                                            onClick={handleLogout}
                                        >
                                            <FontAwesomeIcon icon={faRightFromBracket} /> Logout
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <ul className="nav header-navbar-rht">
                            {!token ? (
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link header-login" to="/login">
                                            <span><i className="fa-regular fa-user"></i></span>Sign In
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link header-reg" to="/register">
                                            <span><i className="fa-solid fa-lock"></i></span>Sign Up
                                        </Link>
                                    </li>
                                </>
                            ) : (
                                // <li className="nav-item">
                                //     <button className="nav-link header-login btn-logout" onClick={handleLogout}>
                                //         Logout
                                //     </button>
                                // </li>
                                // <li className="nav-item">
                                //        <button className="nav-link header-login btn-logout" onClick={handleLogout}>
                                //           Logout
                                //        </button>
                                // </li>
                                <li className="nav-item">
                                    <button
                                        className="nav-link header-login btn-logout"
                                        onClick={handleLogout}
                                    >
                                        <FontAwesomeIcon icon={faRightFromBracket} /> Logout
                                    </button>
                                </li>

                            )}
                        </ul>
                    </nav>
                </div>
            </header>
        </>
    );
};

export default Header;