import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, registerUser } from '../../features/auth/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faEye, faEyeSlash, faPhone, faAddressCard, faUserGear } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import './AuthForm.css';

const AuthForm = ({ isRegister }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [c_password, setC_password] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isRegister) {
            if (password !== c_password) {
                setErrorMessage('Passwords do not match');
                return;
            }
            dispatch(registerUser({ name, phone, email, password, c_password, address }));
        } else {
            dispatch(loginUser({ email, password }));
        }
    };

    return (
        <div className="auth-wrapper">
            <div className="auth-container">
                <h2>{isRegister ? 'Create an Account' : 'Sign In'}</h2>
                <p className="subtitle">
                    {isRegister ? 'Register to get started' : 'We’ll send a confirmation code to your email.'}
                </p>
                <form onSubmit={handleSubmit} className="auth-form">
                    {isRegister && (
                        <>
                            <div className="input-block">
                                <label>Name <span className="required">*</span></label>
                                <div className="input-icon">
                                    <FontAwesomeIcon icon={faUserGear} className="input-fa-icon" />
                                    <input
                                        type="text"
                                        placeholder="Enter your Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="input-block">
                                <label>Phone Number <span className="required">*</span></label>
                                <div className="input-icon">
                                    <FontAwesomeIcon icon={faPhone} className="input-fa-icon" />
                                    <input
                                        type="text"
                                        placeholder="Enter your phone number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div className="input-block">
                        <label>Email <span className="required">*</span></label>
                        <div className="input-icon">
                            <FontAwesomeIcon icon={faEnvelope} className="input-fa-icon" />
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="input-block">
                        <label>Password <span className="required">*</span></label>
                        <div className="input-icon">
                            <FontAwesomeIcon icon={faLock} className="input-fa-icon" />
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className="toggle-password"
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                    </div>

                    {isRegister && (
                        <>
                            <div className="input-block">
                                <label>Confirm Password <span className="required">*</span></label>
                                <div className="input-icon">
                                    <FontAwesomeIcon icon={faLock} className="input-fa-icon" />
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Confirm your password"
                                        value={c_password}
                                        onChange={(e) => setC_password(e.target.value)}
                                    />
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEyeSlash : faEye}
                                        className="toggle-password"
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {isRegister && (
                        <div className="input-block">
                            <label>Address <span className="required">*</span></label>
                            <div className="input-icon">
                                <FontAwesomeIcon icon={faAddressCard} className="input-fa-icon" />
                                <input
                                    type="text"
                                    placeholder="Enter your address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                        </div>
                    )}

                    <button type="submit" className="btn btn-submit">
                        {isRegister ? 'Register' : 'Login'}
                    </button>

                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                    {auth.error && <p className="error-message">{auth.error}</p>}

                    {/* Show success message */}
                    {auth.message && isRegister && (
                        <div className="success-message">
                            <p>{auth.message}</p>
                            <button className="btn btn-login" onClick={() => navigate('/login')}>
                                Go to Login
                            </button>
                        </div>
                    )}

                    <div className="register-link">
                        {isRegister ? (
                            <>
                                Already have an account? <Link to="/login">Login</Link>
                            </>
                        ) : (
                            <>
                                Don't have an account? <Link to="/register">Register</Link>
                            </>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AuthForm;
