import React from 'react';
import './BookingSummary.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const BookingSummary = () => {
    const bookingData = {
        pickupLocation: "123 Main St, Springfield",
        destinationLocation: "456 Elm St, Shelbyville",
        driverName: "Rahul",
        driverRating: 4.5,
        duration: "30 mins",
        totalFare: 320,
        carNumber: "XYXYXY32",
        carImage: "/assets/img/car7.jpg",
    };

    return (
        <div className="booking-summary">
            <div className="back-arrow">
                <Link to="/"> {/* Use the path that matches your route in App.js */}
                    {/* You can replace this with an actual arrow icon, using a character or an icon library like FontAwesome */}
                    <FontAwesomeIcon icon={faArrowLeft} /> {/* Left arrow character */}
                </Link>
            </div>
            <h2 className="booking-title">Booking Summary</h2>
            <div className="summary-card">
                <div className="car-info">
                    <img src={bookingData.carImage} alt="Car" className="car-image" />
                    <div className="driver-info">
                        <p><strong>Driver Name:</strong> {bookingData.driverName}</p>
                        <p><strong>Rating:</strong> <span className="rating-star">{bookingData.driverRating} ★</span></p>
                        <p><strong>Car Number:</strong> {bookingData.carNumber}</p>
                    </div>
                </div>
                <div className="booking-details">
                    <p><strong>Pickup:</strong> {bookingData.pickupLocation}</p>
                    <p><strong>Destination:</strong> {bookingData.destinationLocation}</p>
                    <p><strong>Duration:</strong> {bookingData.duration}</p>
                    <p><strong>Total Fare:</strong> ₹{bookingData.totalFare}</p>
                </div>
            </div>
        </div>
    );
};

export default BookingSummary;