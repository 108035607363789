import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login, register } from '../../api';

const initialState = {
    token: null,
    user: null,          // Added user to track the logged-in user
    status: 'idle',
    error: null,
    message: null,
};

export const loginUser = createAsyncThunk('auth/loginUser', async (credentials, { rejectWithValue }) => {
    try {
        const response = await login(credentials);
        return response.data;
    } catch (error) {
        // Use rejectWithValue to pass API error responses
        return rejectWithValue(error.response.data || 'Login failed');
    }
});

export const registerUser = createAsyncThunk('auth/registerUser', async (userInfo, { rejectWithValue }) => {
    try {
        const response = await register(userInfo);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data || 'Registration failed');
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null;
            state.user = null;
            state.status = 'idle';   // Reset status
            state.error = null;      // Clear error
            state.message = null;    // Clear message
        },
    },
    extraReducers: (builder) => {
        builder
            // Login user
            .addCase(loginUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.error = null; // Clear any previous errors
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })

            // Register user
            .addCase(registerUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.token = action.payload.data.token;
                state.message = action.payload.message;  // Capture success message
                state.error = null; // Clear any previous errors
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            });
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
