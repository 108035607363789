import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from '../features/auth/authSlice';
import productReducer from '../features/products/productSlice';
import bookingReducer from '../features/booking/bookingSlice';
import vehicleReducer from '../features/vehicleSlice';

// Persist configuration
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'bookings', 'vehicles'], // Make sure all the required slices are whitelisted
};

// Root reducer
const rootReducer = combineReducers({
    auth: authReducer,
    products: productReducer,
    bookings: bookingReducer,
    vehicles: vehicleReducer,
});

// Apply persist to the root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Store configuration
const store = configureStore({
    reducer: persistedReducer,  // Use the persisted reducer here
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Disabling serializableCheck for redux-persist compatibility
        }),
});

// Persistor setup
const persistor = persistStore(store);

export { store, persistor };
