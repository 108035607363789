// src/api/products.js
import axios from 'axios';


const api = axios.create({
    baseURL: "https://devgas.astergo.in",
    // baseURL: "http://127.0.0.1:8000",
});


export const fetchProducts = async () => {
    const response = await api.get('/api/products');
    return response.data;
};

export const createProduct = async (product) => {
    const response = await api.post('/api/products', product);
    return response.data;
};

export const updateProduct = async (product) => {
    const response = await api.put(`/api/products/${product.id}`, product);
    return response.data;
};

export const deleteProduct = async (id) => {
    const response = await api.delete(`/api/products/${id}`);
    return response.data;
};

//booking

export const fetchBookingDetails = async (bookingId) => {
    const response = await api.get(`/api/fetchBookingDetails/${bookingId}`);
    return response.data;
};

export const fetchBookingHistory = async (userId) => {
    const response = await api.get('/api/products');
    return response.data;
};

export const updateBookingStatus = async (bookingId, status) => {
    const response = await api.put(`/api/products/${bookingId.id}`, status);
    return response.data;
};


//vehicle

export const fetchVehicles = async () => {
    const response = await api.get('/api/categoty');
    return response.data;
};
