import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingDetails, getBookingHistory } from '../../features/booking/bookingSlice';

const BookingHistory = () => {
    const dispatch = useDispatch();
    const { bookingHistory, bookingDetails, status, error } = useSelector((state) => state.booking);
    const userId = 1; // Replace with actual user ID

    useEffect(() => {
        dispatch(getBookingHistory(userId));
    }, [dispatch, userId]);

    if (status === 'loading') {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div>
            <h2>Booking History</h2>
            <ul>
                {bookingHistory.map((booking) => (
                    <li key={booking.id}>{booking.details} - {booking.status}</li>
                ))}
            </ul>

            {bookingDetails && (
                <div>
                    <h2>Current Booking</h2>
                    <p>{bookingDetails.details}</p>
                    <p>Status: {bookingDetails.status}</p>
                </div>
            )}
        </div>
    );
};

export default BookingHistory;
