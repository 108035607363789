import React, { useState } from 'react';
import './VehicleList.css';

const VehicleList = ({ vehicles, openModal, fareDetails }) => {

    const [vahiclePrice, setVahiclePrice] = useState(fareDetails);

    return (
        <>
            {vehicles.map((vehicle, index) => (
                // <div className="listview-car" key={index}>
                //     <div className="card">
                //         <div className="blog-widget d-flex">
                //             <div className="blog-img">
                //                 <a href="listing-details.html">
                //                     <img src="/assets/img/car-list-1.jpg" className="img-fluid" alt="blog-img" />
                //                 </a>
                //             </div>
                //             <div className="bloglist-content w-100">
                //                 <div className="card-body">
                //                     <div className="blog-list-head d-flex">
                //                         <div className="blog-list-title">
                //                             <h3><a href="#">{vehicle.model}</a></h3>
                //                             <h6>Category : <span>{vehicle.vehicle_type}</span></h6>
                //                         </div>
                //                         <div className="blog-list-rate">

                //                             <h6>Rs. {fareDetails.totalFare} </h6>
                //                         </div>
                //                     </div>
                //                     <div className="listing-details-group">
                //                         <ul>
                //                             <li>
                //                                 <span><img src="/assets/img/icons/car-parts-05.svg" alt="Auto" /></span>
                //                                 <p>Auto</p>
                //                             </li>
                //                             <li>
                //                                 <span><img src="/assets/img/icons/car-parts-02.svg" alt="10 KM" /></span>
                //                                 <p>{fareDetails.distance} KM</p>
                //                             </li>
                //                             <li>
                //                                 <span><img src="/assets/img/icons/car-parts-03.svg" alt="Petrol" /></span>
                //                                 <p>{vehicle.fuel_type}</p>
                //                             </li>


                //                             <li>
                //                                 <span><img src="/assets/img/icons/car-parts-06.svg" alt="Persons" /></span>
                //                                 <p>{vehicle.number_of_seats} Persons</p>
                //                             </li>
                //                         </ul>
                //                     </div>
                //                     <div className="blog-list-head list-head-bottom d-flex">
                //                         {/* <div className="blog-list-title">
                //                             <div className="title-bottom">
                //                                 <div className="car-list-icon">
                //                                     <img src="/assets/img/cars/car-list-icon-01.png" alt="car" />
                //                                 </div>
                //                                 <div className="address-info">
                //                                     <h5><a href="#">Toyota Of Lincoln Park</a></h5>
                //                                     <h6><i className="feather-map-pin me-2"></i>Newyork, USA</h6>
                //                                 </div>
                //                             </div>
                //                         </div> */}
                //                         <div className="listing-button">
                //                             <a className="btn btn-order" onClick={() => openModal(vehicle)}><span><i className="feather-calendar me-2"></i></span>Booking Now</a>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
                //                 <div className="listview-car" key={index}>
                //     <div className="card stylish-card">
                //         <div className="blog-widget d-flex">
                //             <div className="blog-img stylish-img">
                //                 <a href="listing-details.html">
                //                     <img src="/assets/img/car-list-1.jpg" className="img-fluid" alt="blog-img" />
                //                 </a>
                //             </div>
                //             <div className="bloglist-content w-100">
                //                 <div className="card-body stylish-body">
                //                     <div className="blog-list-head d-flex justify-content-between align-items-center">
                //                         <div className="blog-list-title">
                //                             <h3 className="vehicle-model"><a href="#">{vehicle.model}</a></h3>
                //                             <h6 className="vehicle-category">Category : <span>{vehicle.vehicle_type}</span></h6>
                //                         </div>
                //                         <div className="blog-list-rate">
                //                             <h6 className="vehicle-price">Rs. {fareDetails.totalFare}</h6>
                //                         </div>
                //                     </div>
                //                     <div className="listing-details-group">
                //                         <ul className="vehicle-features">
                //                             <li>
                //                                 <span><img src="/assets/img/icons/car-parts-05.svg" alt="Auto" /></span>
                //                                 <p>Auto</p>
                //                             </li>
                //                             <li>
                //                                 <span><img src="/assets/img/icons/car-parts-02.svg" alt="Distance" /></span>
                //                                 <p>{fareDetails.distance} KM</p>
                //                             </li>
                //                             <li>
                //                                 <span><img src="/assets/img/icons/car-parts-03.svg" alt="Fuel Type" /></span>
                //                                 <p>{vehicle.fuel_type}</p>
                //                             </li>
                //                             <li>
                //                                 <span><img src="/assets/img/icons/car-parts-06.svg" alt="Seats" /></span>
                //                                 <p>{vehicle.number_of_seats} Persons</p>
                //                             </li>
                //                         </ul>
                //                     </div>
                //                     <div className="blog-list-head list-head-bottom d-flex justify-content-end">
                //                         <div className="listing-button">
                //                             <a className="btn btn-order stylish-button" onClick={() => openModal(vehicle)}>
                //                                 <span><i className="feather-calendar me-2"></i></span>Booking Now
                //                             </a>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
                <div className="listview-car" key={index}>
                    <div className="card car-card">
                        <div className="d-flex align-items-top ">
                            {/* <div className="blog-img" style={{ flex: '0 0 100px', paddingRight: '15px' }}>
                <a href="listing-details.html">
                    <img src="/assets/img/car-list-1.jpg" className="img-fluid" alt="blog-img" style={{ borderRadius: '8px' }} />
                </a>
            </div> */}
                            <div className="car-image p-4" style={{ flex: '0 0 136px', marginTop: '-4px', paddingRight: '10px', transform: 'translateX(-30px)' }}>
                                <a href="listing-details.html"  >
                                    <img src="/assets/img/car7.jpg" className="img-fluid " alt="car" />
                                </a>
                                <div className="blog-list-title" >
                                    <h3><a href="#" style={{ fontSize: '15px', fontWeight: '400', color: 'black' }}>{vehicle.model}</a></h3>
                                    <h6 style={{ fontSize: '14px', color: 'black' }}><span>{vehicle.vehicle_type}</span></h6>
                                </div>
                            </div>
                            <div className="bloglist-content w-100" style={{ transform: 'translateX(-20px)' }}>
                                <div className="card-body">

                                    <div className="listing-details-group mt-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>

                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <img src="/assets/img/icons/taxi-driver.svg" alt="Auto" style={{ width: '32px', marginRight: '5px', marginLeft: '-24px' }} />
                                            <p style={{ fontSize: '13px', margin: '-10', fontWeight: '600', fontFamily: 'sans-Serif' }}>Mahesh kumar choudhury</p>
                                        </div>


                                        <ul style={{ display: 'flex', gap: '26px', listStyleType: 'none', padding: 0, margin: 0, width: '100%', justifyContent: 'space-between' }}>
                                            <li style={{ display: 'flex', alignItems: 'center', flex: '1 1 0' }}>
                                                <img src="/assets/img/icons/license-plate.svg" alt="License Plate" style={{ width: '30px', marginRight: '5px', marginLeft: '-26px' }} />
                                                <p style={{ fontSize: '14px', margin: 0 }}>XY32414545</p>
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', flex: '1 1 0' }}>
                                                <img src="/assets/img/icons/luggage.svg" alt="Luggage" style={{ width: '24px', marginRight: '5px', marginLeft: '-19px' }} />
                                                <p style={{ fontSize: '14px', margin: 0 }}>4</p>
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', flex: '1 1 0' }}>
                                                <img src="/assets/img/icons/family.svg" alt="Persons" style={{ width: '30px', marginRight: '8px', marginLeft: '-20px' }} />
                                                <p style={{ fontSize: '14px', margin: 0 }}>{vehicle.number_of_seats}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* {{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '10px' }} */}

                                    {/* <div className="blog-list-head list-head-bottom d-flex justify-content-end mt-3">
                        <div className="listing-button">
                            <a className="btn btn-order" onClick={() => openModal(vehicle)} style={{ display:'flex', backgroundColor: '#3498db', color: '#fff', padding: '8px 16px',justifyContent: 'flex-end', borderRadius: '24px', marginBottom:'10px', textDecoration: 'none' }}>
                                <span></span>Booking Now
                            </a>
                        </div>
                    </div> */}

                                    <div className="blog-list-head list-head-bottom d-flex justify-content-end mt-3 ">
                                        <div className="listing-button">
                                            <a
                                                className="btn btn-order book-now-button"
                                                onClick={() => openModal(vehicle)}
                                            >
                                                {/* Stylish Arrow span */}
                                                <span className="arrow" >&#10140;</span>
                                                Booking Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            ))}

            {/* {vehicles.map((vehicle, index) => (
                <div key={index} className="vehicle-card">
                    <h3>{vehicle.name}</h3>
                    <p>{vehicle.id}</p>
                    <button onClick={() => openModal(vehicle)}>Book Now</button>
                </div>
            ))} */}
        </>

    );
};

export default VehicleList;
